<template>
<v-container class="pa-1 ma-0">
  <v-row class="ma-0 pr-5 pt-8 d-flex align-center">
    <span style="width:10%; font-size:16px;">时间</span>
    <div style="width:90%; border: solid;
    border-width: 1px;
    border-color: #c5c5c5;">
      <date-range-picker style="color: #c5c5c5;" ref="picker" opens="right" :locale-data="{ 
				firstDay: 1,
				format: 'yyyy-mm-dd',
				applyLabel: '确定',
				cancelLabel: '取消',
				daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
				monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
				}" minDate="2018-01-01" maxDate="2050-12-31" :dateRange="dateRange" :ranges="false" @update="updateDate" :append-to-body="true">
      </date-range-picker>
    </div>
  </v-row>

  <v-row class="ma-0 pr-5 pt-2 d-flex align-center justify-start">
    <span style="width:10%; font-size:16px;">负责人</span>

    <div v-if="selObj.tab==1 && selObj.type==1" style="width:90%;" class="d-flex align-center justify-start">
      <v-checkbox v-model="owners[0].checked" label="" class="pr-0" @change=""></v-checkbox>
      <UserAvatar :fullName="owners[0].userName" :name="owners[0].userName.slice(0,1)" width='30px' height='30px' :userId="owners[0].userId" />
      <span class="pl-1">{{owners[0].userName}}</span>
    </div>

    <div v-if="selObj.tab==1 && selObj.type==2" style="width:90%;" class="d-flex justify-start">
      <v-checkbox v-model="allMemberChk" label="全部成员" class="pr-8" @change="onChangeChk1"></v-checkbox>
      <v-checkbox v-model="userSpecChk" label="自定义选择成员" @change="onChangeChk2"></v-checkbox>
    </div>
  </v-row>

  <v-row v-if="(selObj.tab==1 && selObj.type==2 && userSpecChk) || selObj.tab==2" class="ma-0 pr-5 pt-0 d-flex align-center justify-start">
    <span style="width:10%; font-size:16px;"></span>
    <div style="width:90%; flex-wrap: wrap;" class="d-flex align-center justify-start">
      <div v-for="user in owners.slice(0,moreVal)" class="d-flex align-center justify-start">
        <v-checkbox v-model="user.checked" label="" class="pr-0" @change=""></v-checkbox>
        <UserAvatar :fullName="user.userName" :name="user.userName.slice(0,1)" width='30px' height='30px' :userId="user.userId" />
        <span class="pl-1 pr-8">{{user.userName}}</span>
      </div>
      <div v-if="owners.length>moreVal">
        <v-icon @click="moreVal=moreVal+20">mdi-more</v-icon>..{{owners.length-moreVal}}
      </div>
    </div>
  </v-row>

  <v-row class="ma-0 pr-5 pt-0 d-flex align-center justify-start">
    <span style="width:10%; font-size:16px;">显示执行</span>
    <div style="width:90%;" class="d-flex justify-start">
      <v-checkbox v-model="showTaskChk" class="pr-8" @change=""></v-checkbox>
      <v-spacer></v-spacer>
    </div>
  </v-row>

  <v-row class="ma-0 pr-5 pt-2 d-flex align-center justify-start">
    <v-btn dark color="primary" @click="onGenerateTable" :disabled="!chkGen">
      生成目标统计表
    </v-btn>
    <v-spacer></v-spacer>
  </v-row>

  <v-row class="ma-0 pr-5 pt-2 d-flex align-center justify-start">
    <zk-table :data="this.thisTable" :columns="itemHeader" children-prop="task_tasks" index-text="#" :stripe="false" :border="false" :show-header="true" :show-summary="false" :show-row-hover="true" :show-index="true" :tree-type="true" :is-fold="false" :expand-type="false" :selection-type="false">
      <template slot="colName" scope="scope">
        <span>
          <v-icon @click="showLink(scope.row)" :style="{color:scope.row._level>=3?'blue':'black'}" small>mdi-link</v-icon>
        </span>
      </template>
      <template slot="colType" scope="scope">
        <span v-if="scope.row.ob_type!=null">
          {{objectType[scope.row.ob_type]}}
        </span>
        <span v-else>
          -
        </span>
      </template>
      <template slot="colCompletion" scope="scope">
        <span v-if="scope.row.ob_progress!=null">
          {{scope.row.ob_progress+'%'}}<br />
          {{objectStatus[scope.row.ob_status]}}
        </span>
        <span v-if="scope.row.kr_completion!=null">
          {{scope.row.kr_completion+'%'}}
        </span>
        <span v-if="scope.row.task_progress!=null">
          {{scope.row.task_progress+'%'}}<br />
          {{taskStatus[scope.row.task_status-1]}}<br />
          {{getExpiredDays(scope.row.task_end_date)}}
        </span>
      </template>
      <template slot="colProgress" scope="scope">
        <span v-if="scope.row.ob_recent_pr!=null">
          {{getFormattedDate(scope.row.ob_recent_pr.created_at)}} {{scope.row.ob_recent_pr.employeeName}}<br />
          {{scope.row.ob_recent_pr.pr_description}}
        </span>
      </template>
      <template slot="colTaskRate" scope="scope">
        {{getCompletionStatus(scope.row.task_tasks)}}
      </template>
      <template slot="colScore" scope="scope">
        <span v-if="scope.row.ob_score!=null">
          {{scope.row.ob_score}}
        </span>
      </template>
      <template slot="colScoreDesc" scope="scope">
        <span v-if="scope.row.ob_score_description!=null">
          {{scope.row.ob_score_description}}
        </span>
      </template>
      <template slot="colOwnerName" scope="scope">
        <span v-if="scope.row.ob_owners!=null">
          {{scope.row.ob_owners['employeeName']}}
        </span>
        <span v-if="scope.row.kr_owners!=null">
          {{scope.row.kr_owners['employeeName']}}
        </span>
        <span v-if="scope.row.task_owner_name!=null">
          {{scope.row.task_owner_name}}
        </span>
      </template>
      <template slot="colDept" scope="scope">
        <span v-if="scope.row.ob_owners!=null">
          {{scope.row.ob_owners['departmentOaName']}}
        </span>
        <span v-if="scope.row.kr_owners!=null">
          {{scope.row.kr_owners['departmentOaName']}}
        </span>
        <span v-if="scope.row.task_dept_name!=null">
          {{scope.row.task_dept_name}}
        </span>
      </template>
      <template slot="colStartDate" scope="scope">
        <span v-if="scope.row.ob_start_date!=null">
          {{scope.row.ob_start_date}}
        </span>
        <span v-if="scope.row.kr_start_date!=null">
          {{scope.row.kr_start_date}}
        </span>
        <span v-if="scope.row.task_start_date!=null">
          {{scope.row.task_start_date}}
        </span>
      </template>
      <template slot="colEndDate" scope="scope">
        <span v-if="scope.row.ob_end_date!=null">
          {{scope.row.ob_end_date}}
        </span>
        <span v-if="scope.row.kr_end_date!=null">
          {{scope.row.kr_end_date}}
        </span>
        <span v-if="scope.row.task_end_date!=null">
          {{scope.row.task_end_date}}
        </span>
      </template>
    </zk-table>
  </v-row>
  <v-row>
    <TaskEdit ref="taskEditor" />
    <RightObjectEditor ref="objectEditor" />
    <RightEditor ref="krEditor" />
  </v-row>

</v-container>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import {
  mapGetters,
  mapActions
} from 'vuex';
import {
  constant
} from '@/constants/constant.js';
import moment from 'moment';

export default {
  props: ['selObj'],
  components: {
    DateRangePicker,
    UserAvatar: () => import('@/components/common/UserAvatar.vue'),
    TaskEdit: () => import('@/components/common/taskEdit/TaskEdit.vue'),
    RightEditor: () => import('@/components/okrgoal/rightInfo/RightEditor.vue'),
    RightObjectEditor: () => import('@/components/okrmap/RightObjectEditor.vue'),
  },
  data() {
    return {
      moreVal: 20,
      allMemberChk: true,
      userSpecChk: false,
      showTaskChk: false,
      objectType: [],
      objectStatus: ['进行中', '已结束'],
      taskStatus: [],
      dateRange: {
        startDate: null,
        endDate: null,
      },

      itemHeader: [{
          label: '目标/KR',
          prop: 'task_name',
          width: '350px'
        },
        {
          label: '',
          type: 'template',
          template: 'colName',
          width: '30px'
        },
        {
          label: '类型',
          type: 'template',
          template: 'colType',
          width: '70px'
        },
        {
          label: '完成度',
          type: 'template',
          template: 'colCompletion',
          width: '70px'
        },
        {
          label: '进展',
          type: 'template',
          template: 'colProgress',
          width: '150px'
        },
        {
          label: '任务完成率',
          type: 'template',
          template: 'colTaskRate',
          width: '100px'
        },
        {
          label: '评分',
          type: 'template',
          template: 'colScore',
          width: '60px'
        },
        {
          label: '评分说明',
          type: 'template',
          template: 'colScoreDesc'
        },
        {
          label: '负责人',
          type: 'template',
          template: 'colOwnerName'
        },
        {
          label: '部门/职位',
          type: 'template',
          template: 'colDept',
          width: '160px'
        },
        {
          label: '开始时间',
          type: 'template',
          template: 'colStartDate'
        },
        {
          label: '结束时间',
          type: 'template',
          template: 'colEndDate'
        },
      ],

      owners: [{
        userId: 0,
        userName: "",
        checked: true,
      }],
      thisTable: [],
    };
  },
  watch: {
    selObj: {
      handler: function (val) {
        if (val.tab == 1 && val.type == 1) {
          this.owners = [{
            userId: this.authUser.id,
            userName: this.authUser.employeeName,
            checked: true,
          }];
        }
        if (val.tab == 1 && val.type == 2) {
          let xiashu_users = this.users.filter(user => {
            return user.user_superior == this.authUser.id;
          }).map(user => {
            let newUser = {}
            newUser.userId = user.id;
            newUser.userName = user.employeeName;
            newUser.checked = true;
            return newUser;
          });
          this.owners = xiashu_users;
          this.owners.unshift({
            userId: this.authUser.id,
            userName: this.authUser.employeeName,
            checked: true,
          })
        }

        if (val.tab == 2 && (val.type + "").includes(".")) {
          let xiashu_users = this.users.filter(user => {
            return (user.user_dp_serial + "." + user.id + ".").includes(val.type);
          }).map(user => {
            let newUser = {}
            newUser.userId = user.id;
            newUser.userName = user.employeeName;
            newUser.checked = true;
            return newUser;
          });
          this.owners = xiashu_users;
          this.moreVal = 20;
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getOkrs({})
    this.objectType = constant.objectType;
    this.taskStatus = constant.taskStatus.map(item => {
      return item.text;
    });

    this.owners = [{
      userId: this.authUser.id,
      userName: this.authUser.employeeName,
      checked: true,
    }];
    let start = new Date();
    let end = new Date();
    start.setDate(1);
    end.setMonth(end.getMonth() + 1);
    end.setDate(1);
    end.setDate(end.getDate() - 1);
    this.dateRange = {
      startDate: start,
      endDate: end,
    };
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
    ...mapGetters('okr', ['okrObj']),
    ...mapGetters("user", ["users"]),
    checkedCount() {
      return this.owners.filter(res => {
        return res.checked;
      }).length;
    },
    chkGen() {
      return (this.selObj.tab == 1 && this.selObj.type == 1 && this.checkedCount) ||
        (this.selObj.tab == 1 && this.selObj.type == 2 && (this.allMemberChk || (this.userSpecChk && this.checkedCount))) ||
        (this.selObj.tab == 2 && this.checkedCount);
    }
  },
  methods: {
    ...mapActions('okr', ['getOkrs']),
    showLink(param) {
      if (!!param.ob_id) {
        this.$refs.objectEditor.open(param)
      }
      if (!!param.kr_id) {
        this.$refs.krEditor.open({
          krObj: param,
          obId: param.kr_parent_object,
          obStatus: 0,
        });
      }
      if (!!param.task_id) {
        this.$refs.taskEditor.openTaskEditor(param);
      }
    },
    updateData() {
      this.getOkrs({})
    },
    getFormattedDate(newDate) {
      return moment(newDate).format("YYYY/MM/DD")
    },
    getExpiredDays(stopDate) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      let s1 = new Date(stopDate);
      let s2 = new Date();
      '过期天'
      let diff = Math.floor((s1 - s2) / _MS_PER_DAY);
      return diff > 0 ? '过期' + diff + '天' : null;
    },
    getCompletionStatus(tasks) {
      let allCnt = tasks.length;
      let completedCnt = 0;
      tasks.forEach(ele => {
        if (ele.kr_completion && ele.kr_completion == 100) {
          completedCnt++;
        }
        if (ele.task_status && (ele.task_status == 1 || ele.task_progress == 100)) {
          completedCnt++;
        }
      });
      return allCnt == 0 ? '' : Math.floor(completedCnt / allCnt * 100.0) + '%(' + completedCnt + '/' + allCnt + ')'
    },
    updateDate(param) {
      this.dateRange = param;
      this.onGenerateTable();
    },
    onChangeChk1() {
      if (this.allMemberChk) {
        this.userSpecChk = false;
      }
      this.owners = this.owners.map(res => {
       // res.checked = this.allMemberChk;
        res.checked = true;
        return res;
      })
    },
    onChangeChk2() {
      if (this.userSpecChk) {
        this.allMemberChk = false;
        
        this.owners = this.owners.map(res => {
          res.checked = true;
          return res;
        })
      }
    },
    onGenerateTable() {
      this.thisTable = this.okrObj.map((obj) => {
        obj['task_tasks'] = obj['ob_results'];
        obj['task_name'] = 'O : ' + obj['ob_name'];
        // delete obj['ob_results'];
        obj['task_tasks'] = obj['task_tasks'].map((kr) => {
          kr['task_name'] = 'KR' + kr['kr_order'] + ' : ' + kr['kr_name'];
          if (this.showTaskChk) {
            kr['task_tasks'] = kr['kr_tasks'];
          } else {
            kr['task_tasks'] = new Array();
          }
          return kr;
        });
        return obj;
      }).filter((res) => {
        let chk = true;
        let startDate = new Date(res.ob_start_date);
        let endDate = new Date(res.ob_end_date);
        if (!!this.dateRange.startDate) {
          let filterStartDate = this.dateRange.startDate;
          let filterEndDate = this.dateRange.endDate;
          chk = !((endDate < filterStartDate) || (filterEndDate < startDate))
        }
        if (!!this.filterDept && !!this.filterDept.id) {
          chk = chk && (res.ob_owners.user_dp_serial + '.').includes(this.filterDept.dp_serial_id + '.' + this.filterDept.id + '.')
        }
        chk = chk && this.owners.filter(owner => {
          return owner.checked && owner.userId == res.ob_owner;
        }).length;
        return chk;
      });
    },
  }
}
</script>

<style scoped>
.vue-daterange-picker {
  width: 100%;
}
</style>
